import { erc721ABI } from "../configure";
import BigNumber from "bignumber.js";
import { enqueueSnackbar } from "../common/redux/actions";
import { writeContract } from "@wagmi/core";

export const approval = async ({
  address,
  tokenAddress,
  contractAddress,
  dispatch,
}) => {
  try {
    const { hash } = await writeContract({
      address: tokenAddress,
      abi: erc721ABI,
      functionName: "setApprovalForAll",
      args: [contractAddress, true],
      account: address,
    });

    dispatch(
      enqueueSnackbar({
        message: hash,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
        hash,
      })
    );
    return new BigNumber(80000000000).toNumber();
  } catch (err) {
    enqueueSnackbar({
      message: "Approve Error",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    });
  }
};
