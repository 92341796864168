export const FETCH_WRAP_BEGIN = "FETCH_WRAP_BEGIN";
export const FETCH_WRAP_SUCCESS = "FETCH_WRAP_SUCCESS";
export const FETCH_WRAP_FAILURE = "FETCH_WRAP_FAILURE";

export const FETCH_WRAP_APPROVAL_BEGIN = "FETCH_WRAP_APPROVAL_BEGIN";
export const FETCH_WRAP_APPROVAL_SUCCESS = "FETCH_WRAP_APPROVAL_SUCCESS";
export const FETCH_WRAP_APPROVAL_FAILURE = "FETCH_WRAP_APPROVAL_FAILURE";

export const FETCH_TOKEN_APPROVAL_BEGIN = "FETCH_TOKEN_APPROVAL_BEGIN";
export const FETCH_TOKEN_APPROVAL_SUCCESS = "FETCH_TOKEN_APPROVAL_SUCCESS";
export const FETCH_TOKEN_APPROVAL_FAILURE = "FETCH_TOKEN_APPROVAL_FAILURE";

export const FETCH_STAKE_BEGIN = "FETCH_STAKE_BEGIN";
export const FETCH_STAKE_SUCCESS = "FETCH_STAKE_SUCCESS";
export const FETCH_STAKE_FAILURE = "FETCH_STAKE_FAILURE";

// generate the following constants:
// FETCH_BATCH_STAKE_BEGIN,
//   FETCH_BATCH_STAKE_SUCCESS,
//   FETCH_BATCH_STAKE_FAILURE,

export const FETCH_BATCH_STAKE_BEGIN = "FETCH_BATCH_STAKE_BEGIN";
export const FETCH_BATCH_STAKE_SUCCESS = "FETCH_BATCH_STAKE_SUCCESS";
export const FETCH_BATCH_STAKE_FAILURE = "FETCH_BATCH_STAKE_FAILURE";
