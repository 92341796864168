import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_DASHBOARD_BEGIN,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from "./constants";
import { contracts, erc721ABI, stakedERC721ABI } from "../../configure";
import _ from "lodash";
import {
  initializeAlchemy,
  getNftsForOwner,
  NftExcludeFilters,
} from "@alch/alchemy-sdk";
import { convertHexToString } from "../../helpers/bignumber";
import BigNumber from "bignumber.js";
import { readContracts, readContract } from "@wagmi/core";

export function fetchDashboard({ address }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_DASHBOARD_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const nftContract = {
          abi: stakedERC721ABI,
          address: contracts.nft.address,
        };

        const stakedNftContract = {
          abi: stakedERC721ABI,
          address: contracts.stakedNFT.address,
        };

        const allowance = await readContract({
          ...nftContract,
          functionName: "isApprovedForAll",
          args: [address, contracts.nftStaking.address],
        });

        // const stats = await axios.get(apiUrl + "staking/stats");
        const stats = {};
        const settings = {
          apiKey: "iGnhEUY1xDTVIN_WIl4bdkiFDWXHg22Z", // Replace with your Alchemy API Key.
          maxRetries: 10,
        };

        const alchemy = initializeAlchemy(settings);
        let nftList = await getNftsForOwner(alchemy, address, {
          excludeFilters: [NftExcludeFilters.SPAM],
          contractAddresses: [
            contracts.nft.address,
            contracts.stakedNFT.address,
          ],
        });

        let nft = _.filter(nftList.ownedNfts, (o) => {
          return (
            _.toLower(o.contract.address) == _.toLower(contracts.nft.address)
          );
        });

        const unstakingIdsList = _.reduce(
          nft,
          (result, o) => {
            result.push(o.tokenId);
            return result;
          },
          []
        );

        let precalls = [];
        let calls = [];

        // get balance of stakedNFT of address
        let userStakedCount = await readContract({
          ...stakedNftContract,
          functionName: "balanceOf",
          args: [address],
        });

        // get tokenId throudgh stakedNFT contract tokenOfOwnerByIndex method with index from 0 to userStakedCount -1
        for (let i = 0; i < userStakedCount; i++) {
          precalls.push({
            ...stakedNftContract,
            functionName: "tokenOfOwnerByIndex",
            args: [address, i],
          });
        }

        const stakedIds = await readContracts({
          contracts: precalls,
        });
        let stakedIdsList = _.map(stakedIds, (o) => {
          return o.result.toString();
        });

        for (let i = 0; i < stakedIdsList.length; i++) {
          calls.push({
            ...stakedNftContract,
            functionName: "stakedInfoOf",
            args: [stakedIdsList[i]],
          });
        }

        const stakedInfoData = await readContracts({
          contracts: calls,
        });
        let stakedOutput = _.map(stakedInfoData, (res, index) => {
          let tokenId = stakedIdsList[index];
          let start = res.result.start.toString();
          let duration = res.result.duration.toString();
          let end = res.result.end.toString();
          return {
            tokenId: tokenId,
            start: start,
            duration: duration,
            end: end,
          };
        });

        const nftData = await readContracts({
          contracts: [
            {
              ...nftContract,
              functionName: "totalSupply",
              args: [],
            },
            {
              ...stakedNftContract,
              functionName: "totalSupply",
              args: [],
            },
          ],
        });

        let data = _.reduce(
          nftList.ownedNfts || [],
          (result, o) => {
            let d = {
              id: o.tokenId,
              type:
                _.find(_.get(o, "rawMetadata.attributes"), {
                  trait_type: "Quality",
                }) || "-",
              img:
                _.get(o, "media[0].thumbnail") || _.get(o, "media[0].gateway"),
            };

            if (
              _.includes(unstakingIdsList, o.tokenId) &&
              o.contract.address.toUpperCase() ==
                contracts.nft.address.toUpperCase()
            ) {
              result.unstakingList.push(d);
            }

            return { ...result, stakingList: stakedOutput };
          },
          {
            allowance,
            unstakingList: [],
            stakingList: [],
            totalNFT: _.get(nftData, `[${0}].result`, 0).toString(),
            totalStakedNFT: _.get(nftData, `[${1}].result`, 0).toString(),
            tokenCount: _.get(stats, "data.tokenCount", "-"),
          }
        );
        dispatch({
          type: FETCH_DASHBOARD_SUCCESS,
          data,
        });
        resolve();
      } catch (error) {
        dispatch({
          type: FETCH_DASHBOARD_FAILURE,
        });
        return reject(error.message || error);
      }
    });

    return promise;
  };
}

export function useFetchDashboard() {
  const dispatch = useDispatch();

  const { detail, fetchDashboardPending } = useSelector(
    (state) => ({
      fetchDashboardPending: state.home.fetchDashboardPending,
      detail: state.home.detail,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchDashboard(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchDashboard: boundAction,
    fetchDashboardPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_BEGIN:
      return {
        ...state,
        fetchDashboardPending: true,
      };

    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        detail: action.data,
        fetchDashboardPending: false,
      };

    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        fetchDashboardPending: false,
      };

    default:
      return state;
  }
}
