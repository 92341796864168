export const tokens = {
  basicRewardAddress: "0x7616113782aadab041d7b10d474f8a0c04eff258",
  rewardTokens: [
    {
      token: "VEE",
      address: "0x7616113782aadab041d7b10d474f8a0c04eff258",
      decimals: 18,
      coingeckoId: "zeeverse",
      rewardPerSecond: 964506172839506000,
    },
  ],
};

export const rarityMapping = {
  0: "rare",
  1: "rare",
  2: "epic",
  3: "rare",
  4: "rare",
  5: "rare",
  6: "rare",
  7: "epic",
  8: "epic",
  9: "rare",
  10: "rare",
  11: "rare",
  12: "rare",
  13: "rare",
  14: "rare",
  15: "rare",
  16: "rare",
  17: "rare",
  18: "rare",
  19: "rare",
  20: "rare",
  21: "rare",
  22: "rare",
  23: "rare",
  24: "rare",
  25: "rare",
  26: "rare",
  27: "rare",
  28: "rare",
  29: "rare",
  30: "rare",
  31: "rare",
  32: "rare",
  33: "rare",
  34: "rare",
  35: "rare",
  36: "rare",
  37: "rare",
  38: "rare",
  39: "rare",
  40: "rare",
  41: "rare",
  42: "rare",
  43: "rare",
  44: "rare",
  45: "rare",
  46: "rare",
  47: "rare",
  48: "rare",
  49: "rare",
  50: "rare",
  51: "rare",
  52: "rare",
  53: "rare",
  54: "rare",
  55: "rare",
  56: "rare",
  57: "rare",
  58: "rare",
  59: "rare",
  60: "rare",
  61: "rare",
  62: "rare",
  63: "rare",
  64: "rare",
  65: "rare",
  66: "rare",
  67: "rare",
  68: "rare",
  69: "rare",
  70: "rare",
  71: "rare",
  72: "rare",
  73: "rare",
  74: "rare",
  75: "rare",
  76: "rare",
  77: "rare",
  78: "rare",
  79: "rare",
  80: "rare",
  81: "rare",
  82: "rare",
  83: "rare",
  84: "rare",
  85: "rare",
  86: "rare",
  87: "rare",
  88: "rare",
  89: "rare",
  90: "rare",
  91: "rare",
  92: "rare",
  93: "rare",
  94: "rare",
  95: "rare",
  96: "rare",
  97: "rare",
  98: "rare",
  99: "rare",
  100: "rare",
  101: "rare",
  102: "rare",
  103: "rare",
  104: "rare",
  105: "rare",
  106: "rare",
  107: "rare",
  108: "rare",
  109: "rare",
  110: "rare",
  111: "rare",
  112: "rare",
  113: "rare",
  114: "rare",
  115: "rare",
  116: "rare",
  117: "rare",
  118: "rare",
  119: "rare",
  120: "rare",
  121: "rare",
  122: "rare",
  123: "rare",
  124: "rare",
  125: "rare",
  126: "rare",
  127: "rare",
  128: "rare",
  129: "rare",
  130: "rare",
  131: "rare",
  132: "rare",
  133: "rare",
  134: "rare",
  135: "rare",
  136: "rare",
  137: "rare",
  138: "rare",
  139: "rare",
  140: "rare",
  141: "rare",
  142: "rare",
  143: "rare",
  144: "rare",
  145: "rare",
  146: "rare",
  147: "rare",
  148: "rare",
  149: "rare",
  150: "rare",
  151: "rare",
  152: "rare",
  153: "rare",
  154: "rare",
  155: "rare",
  156: "rare",
  157: "rare",
  158: "rare",
  159: "rare",
  160: "rare",
  161: "rare",
  162: "rare",
  163: "rare",
  164: "rare",
  165: "rare",
  166: "rare",
  167: "rare",
  168: "rare",
  169: "rare",
  170: "rare",
  171: "rare",
  172: "rare",
  173: "rare",
  174: "rare",
  175: "rare",
  176: "rare",
  177: "rare",
  178: "rare",
  179: "rare",
  180: "rare",
  181: "rare",
  182: "rare",
  183: "rare",
  184: "rare",
  185: "rare",
  186: "rare",
  187: "rare",
  188: "rare",
  189: "rare",
  190: "rare",
  191: "rare",
  192: "rare",
  193: "rare",
  194: "rare",
  195: "rare",
  196: "rare",
  197: "rare",
  198: "rare",
  199: "rare",
  200: "rare",
  201: "rare",
  202: "rare",
  203: "rare",
  204: "rare",
  205: "rare",
  206: "rare",
  207: "rare",
  208: "rare",
  209: "rare",
  210: "rare",
  211: "rare",
  212: "rare",
  213: "rare",
  214: "rare",
  215: "rare",
  216: "rare",
  217: "rare",
  218: "rare",
  219: "rare",
  220: "rare",
  221: "rare",
  222: "rare",
  223: "rare",
  224: "rare",
  225: "rare",
  226: "rare",
  227: "rare",
  228: "rare",
  229: "rare",
  230: "rare",
  231: "rare",
  232: "rare",
  233: "rare",
  234: "rare",
  235: "rare",
  236: "rare",
  237: "rare",
  238: "rare",
  239: "rare",
  240: "rare",
  241: "rare",
  242: "rare",
  243: "rare",
  244: "rare",
  245: "rare",
  246: "rare",
  247: "rare",
  248: "rare",
  249: "rare",
  250: "rare",
  251: "rare",
  252: "rare",
  253: "rare",
  254: "rare",
  255: "rare",
  256: "rare",
  257: "rare",
  258: "rare",
  259: "rare",
  260: "rare",
  261: "rare",
  262: "rare",
  263: "rare",
  264: "rare",
  265: "rare",
  266: "rare",
  267: "rare",
  268: "rare",
  269: "rare",
  270: "rare",
  271: "rare",
  272: "rare",
  273: "rare",
  274: "rare",
  275: "rare",
  276: "rare",
  277: "rare",
  278: "rare",
  279: "rare",
  280: "rare",
  281: "rare",
  282: "rare",
  283: "rare",
  284: "rare",
  285: "rare",
  286: "rare",
  287: "rare",
  288: "rare",
  289: "rare",
  290: "rare",
  291: "rare",
  292: "rare",
  293: "rare",
  294: "rare",
  295: "rare",
  296: "rare",
  297: "rare",
  298: "rare",
  299: "rare",
  300: "rare",
  301: "rare",
  302: "rare",
  303: "rare",
  304: "rare",
  305: "rare",
  306: "rare",
  307: "rare",
  308: "rare",
  309: "rare",
  310: "rare",
  311: "rare",
  312: "rare",
  313: "rare",
  314: "rare",
  315: "rare",
  316: "rare",
  317: "rare",
  318: "rare",
  319: "rare",
  320: "rare",
  321: "rare",
  322: "rare",
  323: "rare",
  324: "rare",
  325: "rare",
  326: "rare",
  327: "rare",
  328: "rare",
  329: "rare",
  330: "rare",
  331: "rare",
  332: "rare",
  333: "rare",
  334: "rare",
  335: "rare",
  336: "rare",
  337: "rare",
  338: "rare",
  339: "rare",
  340: "rare",
  341: "rare",
  342: "rare",
  343: "rare",
  344: "rare",
  345: "rare",
  346: "rare",
  347: "rare",
  348: "rare",
  349: "rare",
  350: "rare",
  351: "rare",
  352: "epic",
  353: "rare",
  354: "rare",
  355: "epic",
  356: "rare",
  357: "epic",
  358: "rare",
  359: "rare",
  360: "rare",
  361: "rare",
  362: "rare",
  363: "rare",
  364: "rare",
  365: "rare",
  366: "rare",
  367: "rare",
  368: "rare",
  369: "rare",
  370: "rare",
  371: "rare",
  372: "rare",
  373: "rare",
  374: "rare",
  375: "rare",
  376: "rare",
  377: "rare",
  378: "rare",
  379: "rare",
  380: "rare",
  381: "epic",
  382: "epic",
  383: "epic",
  384: "epic",
  385: "epic",
  386: "rare",
  387: "rare",
  388: "rare",
  389: "rare",
  390: "epic",
  391: "epic",
  392: "rare",
  393: "rare",
  394: "rare",
  395: "rare",
  396: "rare",
  397: "rare",
  398: "rare",
  399: "epic",
  400: "rare",
  401: "rare",
  402: "epic",
  403: "rare",
  404: "rare",
  405: "rare",
  406: "rare",
  407: "rare",
  408: "epic",
  409: "rare",
  410: "epic",
  411: "rare",
  412: "rare",
  413: "rare",
  414: "rare",
  415: "rare",
  416: "rare",
  417: "rare",
  418: "epic",
  419: "rare",
  420: "rare",
  421: "rare",
  422: "rare",
  423: "rare",
  424: "rare",
  425: "rare",
  426: "rare",
  427: "rare",
  428: "rare",
  429: "rare",
  430: "epic",
  431: "rare",
  432: "rare",
  433: "rare",
  434: "rare",
  435: "rare",
  436: "rare",
  437: "epic",
  438: "epic",
  439: "epic",
  440: "rare",
  441: "rare",
  442: "rare",
  443: "rare",
  444: "rare",
  445: "rare",
  446: "rare",
  447: "rare",
  448: "epic",
  449: "epic",
  450: "rare",
  451: "rare",
  452: "rare",
  453: "rare",
  454: "rare",
  455: "epic",
  456: "rare",
  457: "epic",
  458: "epic",
  459: "epic",
  460: "epic",
  461: "epic",
  462: "epic",
  463: "epic",
  464: "epic",
  465: "epic",
  466: "epic",
  467: "rare",
  468: "rare",
  469: "epic",
  470: "epic",
  471: "rare",
  472: "rare",
  473: "rare",
  474: "epic",
  475: "epic",
  476: "rare",
  477: "epic",
  478: "epic",
  479: "epic",
  480: "epic",
  481: "epic",
  482: "epic",
  483: "epic",
  484: "epic",
  485: "epic",
  486: "rare",
  487: "rare",
  488: "rare",
  489: "rare",
  490: "epic",
  491: "rare",
  492: "epic",
  493: "epic",
  494: "rare",
  495: "rare",
  496: "rare",
  497: "rare",
  498: "epic",
  499: "rare",
  500: "epic",
  501: "rare",
  502: "rare",
  503: "epic",
  504: "rare",
  505: "epic",
  506: "epic",
  507: "epic",
  508: "rare",
  509: "rare",
  510: "rare",
  511: "rare",
  512: "rare",
  513: "rare",
  514: "rare",
  515: "rare",
  516: "rare",
  517: "rare",
  518: "epic",
  519: "rare",
  520: "rare",
  521: "rare",
  522: "epic",
  523: "rare",
  524: "rare",
  525: "rare",
  526: "rare",
  527: "rare",
  528: "epic",
  529: "rare",
  530: "rare",
  531: "rare",
  532: "rare",
  533: "rare",
  534: "rare",
  535: "rare",
  536: "rare",
  537: "rare",
  538: "rare",
  539: "rare",
  540: "rare",
  541: "rare",
  542: "rare",
  543: "rare",
  544: "rare",
  545: "rare",
  546: "rare",
  547: "rare",
  548: "rare",
  549: "rare",
  550: "rare",
  551: "rare",
  552: "rare",
  553: "rare",
  554: "rare",
  555: "rare",
  556: "rare",
  557: "rare",
  558: "rare",
  559: "rare",
  560: "rare",
  561: "rare",
  562: "rare",
  563: "rare",
  564: "rare",
  565: "rare",
  566: "rare",
  567: "rare",
  568: "rare",
  569: "rare",
  570: "rare",
  571: "rare",
  572: "rare",
  573: "rare",
  574: "rare",
  575: "rare",
  576: "rare",
  577: "rare",
  578: "rare",
  579: "rare",
  580: "rare",
  581: "rare",
  582: "rare",
  583: "rare",
  584: "rare",
  585: "rare",
  586: "rare",
  587: "rare",
  588: "rare",
  589: "rare",
  590: "rare",
  591: "rare",
  592: "rare",
  593: "rare",
  594: "rare",
  595: "rare",
  596: "rare",
  597: "rare",
  598: "rare",
  599: "rare",
  600: "rare",
  601: "rare",
  602: "rare",
  603: "rare",
  604: "rare",
  605: "rare",
  606: "rare",
  607: "rare",
  608: "rare",
  609: "rare",
  610: "rare",
  611: "rare",
  612: "rare",
  613: "rare",
  614: "rare",
  615: "rare",
  616: "rare",
  617: "rare",
  618: "rare",
  619: "rare",
  620: "rare",
  621: "rare",
  622: "rare",
  623: "rare",
  624: "rare",
  625: "rare",
  626: "rare",
  627: "rare",
  628: "rare",
  629: "rare",
  630: "rare",
  631: "rare",
  632: "rare",
  633: "rare",
  634: "rare",
  635: "rare",
  636: "rare",
  637: "rare",
  638: "rare",
  639: "rare",
  640: "rare",
  641: "rare",
  642: "rare",
  643: "rare",
  644: "rare",
  645: "rare",
  646: "rare",
  647: "rare",
  648: "rare",
  649: "rare",
  650: "rare",
  651: "rare",
  652: "rare",
  653: "rare",
  654: "rare",
  655: "rare",
  656: "rare",
  657: "rare",
  658: "rare",
  659: "rare",
  660: "legendary",
  661: "legendary",
  662: "legendary",
  663: "legendary",
  664: "legendary",
  665: "legendary",
  666: "rare",
  667: "rare",
  668: "rare",
  669: "rare",
  670: "rare",
  671: "rare",
  672: "rare",
  673: "rare",
  674: "rare",
  675: "rare",
  676: "epic",
  677: "epic",
  678: "rare",
  679: "rare",
  680: "rare",
  681: "rare",
  682: "epic",
  683: "epic",
  684: "epic",
  685: "rare",
  686: "rare",
  687: "epic",
  688: "epic",
  689: "epic",
  690: "epic",
  691: "epic",
  692: "epic",
  693: "epic",
  694: "epic",
  695: "epic",
  696: "epic",
  697: "epic",
  698: "epic",
  699: "rare",
  700: "rare",
  701: "rare",
  702: "epic",
  703: "epic",
  704: "epic",
  705: "epic",
  706: "epic",
  707: "rare",
  708: "rare",
  709: "rare",
  710: "epic",
  711: "rare",
  712: "rare",
  713: "rare",
  714: "epic",
  715: "epic",
  716: "epic",
  717: "rare",
  718: "rare",
  719: "rare",
  720: "rare",
  721: "rare",
  722: "rare",
  723: "rare",
  724: "rare",
  725: "rare",
  726: "rare",
  727: "rare",
  728: "rare",
  729: "rare",
  730: "rare",
  731: "rare",
  732: "rare",
  733: "rare",
  734: "epic",
  735: "epic",
  736: "epic",
  737: "rare",
  738: "rare",
  739: "rare",
  740: "rare",
  741: "rare",
  742: "rare",
  743: "rare",
  744: "rare",
  745: "rare",
  746: "rare",
  747: "rare",
  748: "rare",
  749: "rare",
  750: "rare",
  751: "rare",
  752: "rare",
  753: "rare",
  754: "rare",
  755: "rare",
  756: "rare",
  757: "rare",
  758: "rare",
  759: "rare",
  760: "rare",
  761: "rare",
  762: "rare",
  763: "rare",
  764: "rare",
  765: "rare",
  766: "rare",
  767: "rare",
  768: "rare",
  769: "rare",
  770: "rare",
  771: "rare",
  772: "rare",
  773: "rare",
  774: "rare",
  775: "rare",
  776: "rare",
  777: "rare",
  778: "rare",
  779: "rare",
  780: "rare",
  781: "rare",
  782: "rare",
  783: "rare",
  784: "rare",
  785: "rare",
  786: "rare",
  787: "rare",
  788: "rare",
  789: "rare",
  790: "rare",
  791: "rare",
  792: "rare",
  793: "rare",
  794: "rare",
  795: "rare",
  796: "rare",
  797: "rare",
  798: "rare",
  799: "rare",
  800: "rare",
  801: "rare",
  802: "rare",
  803: "rare",
  804: "rare",
  805: "rare",
  806: "rare",
  807: "rare",
  808: "rare",
  809: "rare",
  810: "rare",
  811: "rare",
  812: "rare",
  813: "rare",
  814: "rare",
  815: "rare",
  816: "rare",
  817: "rare",
  818: "rare",
  819: "rare",
  820: "rare",
  821: "rare",
  822: "rare",
  823: "rare",
  824: "rare",
  825: "rare",
  826: "rare",
  827: "rare",
  828: "rare",
  829: "rare",
  830: "rare",
  831: "rare",
  832: "rare",
  833: "rare",
  834: "rare",
  835: "rare",
  836: "rare",
  837: "rare",
  838: "rare",
  839: "rare",
  840: "rare",
  841: "rare",
  842: "rare",
  843: "rare",
  844: "rare",
  845: "rare",
  846: "rare",
  847: "rare",
  848: "rare",
  849: "rare",
  850: "rare",
  851: "rare",
  852: "rare",
  853: "rare",
  854: "rare",
  855: "rare",
  856: "rare",
  857: "rare",
  858: "rare",
  859: "rare",
  860: "rare",
  861: "rare",
  862: "rare",
  863: "rare",
  864: "rare",
  865: "rare",
  866: "rare",
  867: "rare",
  868: "rare",
  869: "rare",
  870: "rare",
  871: "rare",
  872: "rare",
  873: "rare",
  874: "rare",
  875: "rare",
  876: "rare",
  877: "rare",
  878: "rare",
  879: "rare",
  880: "rare",
  881: "rare",
  882: "rare",
  883: "rare",
  884: "rare",
  885: "rare",
  886: "rare",
  887: "rare",
  888: "rare",
  889: "rare",
  890: "rare",
  891: "rare",
  892: "rare",
  893: "rare",
  894: "rare",
  895: "rare",
  896: "rare",
  897: "rare",
  898: "rare",
  899: "rare",
  900: "rare",
  901: "rare",
  902: "rare",
  903: "rare",
  904: "rare",
  905: "rare",
  906: "rare",
  907: "rare",
  908: "rare",
  909: "rare",
  910: "rare",
  911: "rare",
  912: "rare",
  913: "rare",
  914: "rare",
  915: "rare",
  916: "rare",
  917: "rare",
  918: "rare",
  919: "rare",
  920: "rare",
  921: "rare",
  922: "rare",
  923: "rare",
  924: "rare",
  925: "rare",
  926: "rare",
  927: "rare",
  928: "rare",
  929: "rare",
  930: "rare",
  931: "rare",
  932: "rare",
  933: "rare",
  934: "rare",
  935: "rare",
  936: "rare",
  937: "rare",
  938: "rare",
  939: "rare",
  940: "rare",
  941: "rare",
  942: "rare",
  943: "rare",
  944: "rare",
  945: "rare",
  946: "rare",
  947: "rare",
  948: "rare",
  949: "rare",
  950: "rare",
  951: "rare",
  952: "rare",
  953: "rare",
  954: "rare",
  955: "rare",
  956: "rare",
  957: "rare",
  958: "rare",
  959: "rare",
  960: "rare",
  961: "rare",
  962: "rare",
  963: "rare",
  964: "rare",
  965: "rare",
  966: "rare",
  967: "rare",
  968: "rare",
  969: "rare",
  970: "rare",
  971: "rare",
  972: "rare",
  973: "rare",
  974: "rare",
  975: "rare",
  976: "rare",
  977: "rare",
  978: "rare",
  979: "rare",
  980: "rare",
  981: "rare",
  982: "rare",
  983: "rare",
  984: "rare",
  985: "rare",
  986: "rare",
  987: "rare",
  988: "rare",
  989: "rare",
  990: "epic",
  991: "rare",
  992: "rare",
  993: "rare",
  994: "rare",
  995: "rare",
  996: "legendary",
  997: "legendary",
  998: "legendary",
  999: "rare",
};
