import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Modal,
  IconButton,
  Checkbox,
  Tooltip,
  Slider,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import InfoIcon from "@material-ui/icons/Info";
import Button from "components/CustomButtons/Button.js";
import { useAccount } from "wagmi";
import Stake from "features/stake/Stake";
import { contracts } from "features/configure";
import { useFetchDashboard } from "./redux/hooks";
import DoneIcon from "@material-ui/icons/Done";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomTable from "components/CustomTable/CustomTable.js";
import _ from "lodash";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { approval, batchDeposit, withdraw } from "features/web3";
import moment from "moment";
import { rarityMapping } from "features/configure/tokens";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: 400,
    textAlign: "left",
    color: "white",
    fontSize: 32,
    lineHeight: 1.1,
  },
  dateText: {
    fontWeight: 700,
    fontSize: 34,
  },
  bidInfo: {
    backgroundColor: "rgba(255,255,255,0.2)",
    textAlign: "right",
    marginTop: 30,
    padding: 20,
    fontSize: 44,
    lineHeight: 1.1,
    fontWeight: 700,
  },
  bidSpan: {
    fontSize: 24,
    fontWeight: 500,
  },
  bidField: {
    backgroundColor: "#1E2025",
    marginTop: 50,
    padding: 20,
  },
  card: {
    flexGrow: 1,
    maxWidth: 400,
    verticalAlign: "middle",
    backgroundColor: "#1E2025",
    overflow: "hidden",
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 10,
    marginRight: 10,
    padding: "10px 20px",
    fontSize: 18,
  },
  cardSubTitle: {
    fontSize: 14,
    marginTop: 5,
  },
  timeSpan: {
    fontSize: 28,
    marginLeft: "5px",
  },
  time: {
    fontSize: 32,
    marginLeft: "5px",
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.5)",
    margin: "0 10px",
    padding: 3,
    textAlign: "center",
    verticalAlign: "middle",
  },

  grayText: {
    color: "rgba(255,255,255,0.6)",
  },
  heading: {
    fontSize: 20,
    color: "rgba(255,255,255,0.6)",
    textAlign: "right",
  },
  paper: {
    width: "30%",
    minWidth: 400,
    borderRadius: 30,
    backgroundColor: theme.palette.background.paper,
    padding: "15px 20px",
  },
  modalPaper: {
    minWidth: 300,
    textAlign: "center",
    color: "white",
    backgroundColor: theme.palette.background.paper,
    padding: "55px 25px",
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "white",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const durationMultiplierMapping = {
  1: 1.25,
  2: 1.5,
  3: 1.75,
  4: 2,
  5: 2.25,
  6: 2.5,
  7: 2.75,
  8: 3,
  9: 3.25,
  10: 3.5,
  11: 3.75,
  12: 4,
};

const Overview = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const SECONDS_PER_YEAR = 86400 * 365;
  const { fetchDashboard, detail } = useFetchDashboard();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = React.useState(false);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [alert, setAlert] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [lockedValue, setLockedValue] = useState(12);
  const [agree, setAgree] = useState(false);
  const { address } = useAccount();
  const [dailyPoints, setDailyPoints] = useState(0);

  const rarityWeighting = {
    common: 1,
    rare: 7,
    epic: 49,
    legendary: 343,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSliderChange = (event, newValue) => {
    setLockedValue(newValue);
  };

  useEffect(() => {
    if (address) {
      if (window?.MetaCRMTracking?.manualConnectWallet) {
        window.MetaCRMTracking.manualConnectWallet(address);
      }
      fetchDashboard({
        address,
      });
      const id = setInterval(() => {
        fetchDashboard({
          address,
        });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  useEffect(() => {
    if (selected.length <= 0) {
      setDailyPoints(0);
    } else {
      let durationMultiplier = durationMultiplierMapping[lockedValue];

      let dailyPoints = _.reduce(
        selected,
        function (sum, nft) {
          let rarity = rarityMapping[parseInt(nft.id)];

          let rarityMultiplier = rarityWeighting[rarity];

          return sum + durationMultiplier * rarityMultiplier;
        },
        0
      );
      setDailyPoints(dailyPoints);
    }
  }, [selected, lockedValue]);

  useEffect(() => {
    if (selected.length <= 0) {
      setSelectedIds([]);
    } else {
      setSelectedIds(_.map(selected, "id"));
    }
  }, [selected]);

  return (
    <>
      <img
        src={require("assets/img/banner.png")}
        style={{ height: 400, width: "100%", objectFit: "cover" }}
      />
      <div
        style={{
          position: "relative",
          margin: "0 auto",
          maxWidth: 1100,
          minHeight: "100vh",
        }}
      >
        <div style={{ display: "flex" }}>
          <img
            src={require("assets/img/legendary_land.png")}
            className="lgIcon"
            style={{ marginTop: "-70px" }}
          />
          <div style={{ fontSize: 40, fontWeight: 700, color: "white" }}>
            {contracts.nft.name}
          </div>
        </div>

        <Modal
          className={classes.modal}
          open={alert}
          onClose={() => {
            setAlert(false);
          }}
        >
          <div className={classes.modalPaper}>
            <ErrorOutlineIcon style={{ fontSize: 60 }} />
            <h1>Attention</h1>
            <div style={{ margin: "40px 0", fontSize: 16 }}>
              DYOR before interacting with any staking contracts.
              <br />
              Audits:
              <a
                href="https://gateway.pinata.cloud/ipfs/QmXyKsEeDe1WzDEuT6v95oUdzVLr2B2xRiV51EamsizroV?preview=1"
                target={"_blank"}
              >
                Peckshield
              </a>
              ,
              <a
                href="https://gateway.pinata.cloud/ipfs/QmZLyPfGXNk4nsBKuSYeMndisV6mm3L91bX8TngLQzttGz?preview=1"
                target={"_blank"}
              >
                Certik
              </a>
              , and
              <a
                href="https://certificate.quantstamp.com/full/vault-inc"
                target={"_blank"}
              >
                Quantstamp
              </a>
            </div>
            <Button
              color="secondary"
              onClick={() => {
                setAgree(true);
                setAlert(false);
                setOpen(true);
              }}
            >
              Yes, I understand the risk.{" "}
            </Button>
          </div>
        </Modal>
        <Modal
          className={classes.modal}
          open={openSelect}
          onClose={() => setOpenSelect(false)}
        >
          <div className={classes.paper}>
            <h2 className="cardHeader">Choose to stake</h2>
            <div
              className="darkCard"
              style={{ height: 400, display: "block", overflowY: "scroll" }}
            >
              {_.get(detail, "unstakingList", []).length == 0 ? (
                <div style={{ textAlign: "center" }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      window.open(
                        "https://opensea.io/collection/zeeverse-lands"
                      );
                    }}
                    style={{ padding: 30, marginTop: 150 }}
                  >
                    <img
                      src={require("assets/img/opensea.png")}
                      className="icon"
                    />
                    Buy Zee Land
                  </Button>
                </div>
              ) : (
                _.get(detail, "unstakingList", []).map((nft, index) => (
                  <div key={index} className="row">
                    <Checkbox
                      color="primary"
                      checked={_.some(selected, { id: nft.id })}
                      onChange={(e) => {
                        let newData = _.cloneDeep(selected);
                        let dataIndex = _.findIndex(newData, { id: nft.id });
                        if (dataIndex >= 0) {
                          _.pullAt(newData, [dataIndex]);
                        } else {
                          newData.push({
                            id: nft.id,
                            rarity: nft.type.value,
                          });
                        }
                        setSelected(newData);
                      }}
                    />
                    <div style={{ width: 84 }}>
                      <img
                        src={
                          rarityMapping[parseInt(nft.id)] &&
                          require(`assets/img/${
                            rarityMapping[parseInt(nft.id)]
                          }_land.png`)
                        }
                        style={{
                          height: 64,
                          width: 64,
                          margin: "0 auto",
                          objectFit: "contain",
                        }}
                      />
                    </div>

                    <div className="cardSubHeader">#{nft.id}</div>
                    <div
                      className="cardSubHeader"
                      style={{ width: "40%", textAlign: "right" }}
                    >
                      {nft.type.value}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div style={{ color: "white" }}>
              <Grid container>
                <Grid item xs={6}>
                  Locked for: {lockedValue} months
                </Grid>
                <Grid item xs={6}>
                  weight: {durationMultiplierMapping[lockedValue]}
                </Grid>
              </Grid>
              <PrettoSlider
                min={1}
                max={12}
                style={{ height: 20 }}
                defaultValue={12}
                value={typeof lockedValue === "number" ? lockedValue : "N/A"}
                onChange={handleSliderChange}
                step={1}
              />
            </div>
            <Button
              fullWidth
              color="secondary"
              disabled={_.get(detail, "unstakingList", []).length == 0}
              onClick={() => setOpenSelect(false)}
            >
              Confirm
            </Button>
          </div>
        </Modal>
        <Modal className={classes.modal} open={open} onClose={handleClose}>
          <div className={classes.paper}>
            <Stake
              selected={selected}
              onClose={() => {
                setOpen(false);
                setSelected([]);
              }}
            />
          </div>
        </Modal>

        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ textAlign: "center", color: "white", margin: "50px auto" }}
          >
            <h1>NFT Staking</h1>
            <div style={{ fontSize: 20 }}>Zee Land Staking</div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="card" style={{ height: 250 }}>
              <div className="cardHeader">Total NFT Staked</div>
              <div className="cardLgValue">
                {_.get(detail, "totalStakedNFT", "-")}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="card" style={{ height: 250 }}>
              <div className="cardHeader">Circulating Supply Staked</div>
              <div className="cardLgValue">
                {detail && detail.totalNFT
                  ? parseFloat(
                      (_.get(detail, "totalStakedNFT", 0) * 100) /
                        detail.totalNFT
                    ).toFixed(2)
                  : "-"}
                %
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <CustomTable
              headers={["Image", "ID#", "Unlock in"]}
              contents={_.get(detail, "stakingList", []).map((d) => {
                const endTime = new Date(parseInt(d.end) * 1000);
                const now = new Date();
                const timeDiff = moment(endTime) - moment(now);

                const dur = moment.duration(timeDiff);

                return [
                  <img
                    src={
                      rarityMapping[parseInt(d.tokenId)] &&
                      require(`assets/img/${
                        rarityMapping[parseInt(d.tokenId)]
                      }_land.png`)
                    }
                    style={{ height: 64, marginTop: 7 }}
                  />,
                  d.tokenId,

                  timeDiff > 0 ? (
                    `${dur.months()} months ${dur.days()} days ${dur.hours()} hrs ${dur.minutes()} mins`
                  ) : (
                    <Button
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        withdraw({
                          address,
                          tokenId: d.tokenId,
                          dispatch,
                        });
                      }}
                    >
                      Unstake
                    </Button>
                  ),
                ];
              })}
            ></CustomTable>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className="card">
              <div className="darkCard">
                <img
                  src={require("assets/img/legendary_land.png")}
                  className="icon"
                />
                <div className="cardHeader">Zee Land Staking</div>
              </div>
              <div className="darkCard">
                <img src={require("assets/img/lock.png")} className="smIcon" />
                <div className="cardSubHeader">Hard-Stake locking</div>
              </div>
              <div className="cardTitle">You Are Staking</div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <img
                  src={require("assets/img/legendary_land.png")}
                  style={{ height: 85 }}
                />
                <div className="cardHeader"> {contracts.nft.name}</div>
                <span>
                  {" "}
                  <div className="cardTitle">
                    {" "}
                    Selected IDs:{" "}
                    {selectedIds.length > 0 ? selectedIds.toString() : "N/A"}
                  </div>
                  <div className="cardTitle">
                    Duration: {lockedValue} months
                  </div>
                  <IconButton
                    style={{ padding: 0, marginTop: 10 }}
                    onClick={() => {
                      setOpenSelect(true);
                    }}
                  >
                    <img
                      src={require("assets/img/select.png")}
                      style={{ height: 35 }}
                    />
                  </IconButton>
                </span>
              </div>
              <div className="cardTitle">
                Your Expected Power
                <Tooltip
                  title={
                    <div
                      style={{
                        fontSize: 13,
                        fontFamily: "Tomorrow, Prompt",
                        lineHeight: 1.2,
                      }}
                    >
                      Power are determined by the duration for which you staked.
                      Click the 'Stake' button to proceed with the stake.
                    </div>
                  }
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <div className="cardHeader">{dailyPoints}</div>
              </div>
              <div style={{ padding: 15 }}>
                {detail.allowance ? (
                  <Button
                    color="secondary"
                    fullWidth
                    disabled={selectedIds.length == 0}
                    onClick={async () => {
                      try {
                        await batchDeposit({
                          address,
                          tokenIds: selectedIds,
                          duration: lockedValue,
                          dispatch,
                        });
                        setSelected("");
                      } catch (err) {
                        console.log("err", err);
                      }
                    }}
                  >
                    Stake
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      approval({
                        address,
                        tokenAddress: contracts.nft.address,
                        contractAddress: contracts.nftStaking.address,
                        dispatch,
                      });
                    }}
                  >
                    Approve
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Overview;
