export const pools = [
  {
    pid: 0,
    stakedTokenName: "VEE",
    stakedTokenSymbol: "VEE",
    rewardTokenName: "VEE",
    stakedTokenDecimals: 18,
    tokenAddress: "0x7616113782aadab041d7b10d474f8a0c04eff258",
    poolAddress: "0x9abb5861e3a1eDF19C51F8Ac74A81782e94F8FdC",
    getUrl:
      "https://app.uniswap.org/#/swap?outputCurrency=0x94e9eb8b5ab9fd6b9ea3169d55ffade62a01702e&inputCurrency=ETH&chain=mainnet",
    weight: 1,
    toFixed: 2,
  },
];
