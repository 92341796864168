import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { contracts, apiUrl } from "features/configure";

import _ from "lodash";
import { useFetchStake } from "features/stake/redux/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Slider, Modal, IconButton } from "@material-ui/core";
import { useAccount } from "wagmi";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "30%",
    minWidth: 400,
    borderRadius: 30,
    backgroundColor: theme.palette.background.paper,
    padding: "15px 15px",
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "white",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function Stake({ selected, onClose }) {
  const SECONDS_PER_YEAR = 86400 * 365;
  const { fetchStake, fetchStakePending } = useFetchStake();
  const { address } = useAccount();

  const onDeposit = async () => {
    await fetchStake({
      address,
      tokens: _.map(selected, "id"),
    });
    onClose();
  };

  return (
    <div style={{}}>
      <Grid
        container
        spacing={1}
        style={{ maxWidth: 600, margin: "10px auto" }}
      >
        <Grid item xs={12} sm={12}>
          {fetchStakePending ? (
            <div style={{ height: 300 }} className="column">
              <CircularProgress />
              <h2>Loading...</h2>
            </div>
          ) : (
            <div style={{ height: 600, overflowY: "scroll" }}>
              <div className="cardHeader">Confirm Soft-Stake</div>

              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <img
                  src={require("assets/img/legendary_land.png").default}
                  style={{ height: 85 }}
                />
                <div className="cardHeader"> {contracts.nft.name}</div>
                <span className="cardSubHeader">{selected.length} Lands</span>
              </div>
              <div
                style={{
                  color: "white",
                  fontWeight: 500,
                  fontSize: 14,
                  padding: "0 10px",
                }}
              >
                Campaign Duration: JULY,7,2023 - JULY,6,2024
                <span className="cardTitle"></span>
              </div>
              <div
                style={{
                  color: "white",
                  fontWeight: 500,
                  fontSize: 14,
                  padding: "0 10px",
                }}
              >
                Campaign Leaderboard Rewards:{" "}
                <span className="cardTitle"></span>
              </div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <span className="row">
                  <img
                    src={require("assets/img/vee_token.png").default}
                    style={{ height: 35, marginRight: 10 }}
                  />
                  <span className="cardSubHeader">Rank #1</span>
                </span>

                <span className="cardSubHeader">156,666 $ZEEV</span>
              </div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <span className="row">
                  <img
                    src={require("assets/img/vee_token.png").default}
                    style={{ height: 35, marginRight: 10 }}
                  />
                  <span className="cardSubHeader">Rank #2</span>
                </span>

                <span className="cardSubHeader">120,000 $ZEEV</span>
              </div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <span className="row">
                  <img
                    src={require("assets/img/vee_token.png").default}
                    style={{ height: 35, marginRight: 10 }}
                  />
                  <span className="cardSubHeader">Rank #3</span>
                </span>

                <span className="cardSubHeader">90,000 $ZEEV</span>
              </div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <span className="row">
                  <img
                    src={require("assets/img/vee_token.png").default}
                    style={{ height: 35, marginRight: 10 }}
                  />
                  <span className="cardSubHeader">Rank #4</span>
                </span>

                <span className="cardSubHeader">60,000 $ZEEV</span>
              </div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <span className="row">
                  <img
                    src={require("assets/img/vee_token.png").default}
                    style={{ height: 35, marginRight: 10 }}
                  />
                  <span className="cardSubHeader">Rank #5</span>
                </span>

                <span className="cardSubHeader">45,000 $ZEEV</span>
              </div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <span className="row">
                  <img
                    src={require("assets/img/vee_token.png").default}
                    style={{ height: 35, marginRight: 10 }}
                  />
                  <span className="cardSubHeader">Rank #6 - #10</span>
                </span>

                <span className="cardSubHeader">18,000 $ZEEV</span>
              </div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <span className="row">
                  <img
                    src={require("assets/img/vee_token.png").default}
                    style={{ height: 35, marginRight: 10 }}
                  />
                  <span className="cardSubHeader">Rank #11 - #15</span>
                </span>

                <span className="cardSubHeader">12,000 $ZEEV</span>
              </div>
              <div
                className="darkCard"
                style={{ justifyContent: "space-between" }}
              >
                <span className="row">
                  <img
                    src={require("assets/img/vee_token.png").default}
                    style={{ height: 35, marginRight: 10 }}
                  />
                  <span className="cardSubHeader">Rank #16 - #20</span>
                </span>

                <span className="cardSubHeader">9,000 $ZEEV</span>
              </div>
              <div className="darkCard" style={{ fontSize: 12 }}>
                If you transfer or sell your NFTs during "soft-locked" the stake
                will become DISQUALIFIED and you will no longer be eligible for
                rewards.
              </div>
              <div style={{ padding: 15 }}>
                <Button color="primary" fullWidth onClick={onDeposit}>
                  Stake
                </Button>
              </div>
              {/* <div className="darkCard">{renderInputField(true)}</div> */}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
