import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import opensea from "assets/img/opensea.png";
import googleplay from "assets/img/google_play.png";
const checkSelected = (location, linkTo) => {
  if (linkTo.length < 5) return location == "#" + linkTo;
  return location.indexOf("#" + linkTo) >= 0;
};
const renderListItem = (name, color, linkTo, location) => {
  const selected = checkSelected(location, linkTo);
  return (
    <LinkButton
      color={color}
      style={{ padding: 0 }}
      title={name}
      href={linkTo}
      selected={selected}
    />
  );
};
const MenuItems = ({ handleDrawerToggle, style, color = "white" }) => {
  let currentLocation = window.location.hash;
  return (
    <div style={style}>
      <Hidden mdUp>
        <ListItem
          button
          component={Link}
          to="/overview"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={"Overview"} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/leaderboard"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={"Leaderboard"} />
        </ListItem>
        <div>
          <a
            href="https://opensea.io/collection/zeeverse-lands"
            target="_blank"
          >
            <img
              src={opensea}
              style={{
                height: "25px",
                marginTop: 10,
                objectFit: "contain",
                marginRight: 20,
                marginLeft: 20,
              }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.zeeverse.zee"
            target="_blank"
          >
            <img
              src={googleplay}
              style={{
                height: "25px",
                marginTop: 10,
                objectFit: "contain",
                marginRight: 20,
                marginLeft: 20,
              }}
            />
          </a>
        </div>
        <ConnectWallet headerButton />
      </Hidden>
      <Hidden smDown>
        <div>
          <Box
            display="flex"
            justifyContent={"flex-start"}
            alignItems={"center"}
            style={{ marginLeft: "-16px" }}
          >
            {renderListItem("Overview", color, "/overview", currentLocation)}
            {renderListItem("Rewards", color, "/reward", currentLocation)}
            {renderListItem(
              "Leaderboard",
              color,
              "/leaderboard",
              currentLocation
            )}
            <a
              href="https://opensea.io/collection/zeeverse-lands"
              target="_blank"
            >
              <img
                src={opensea}
                style={{
                  height: "25px",
                  marginTop: 10,
                  objectFit: "contain",
                  marginRight: 20,
                  marginLeft: 20,
                }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.zeeverse.zee"
              target="_blank"
            >
              <img
                src={googleplay}
                style={{
                  height: "25px",
                  marginTop: 10,
                  objectFit: "contain",
                  marginRight: 20,
                  marginLeft: 10,
                }}
              />
            </a>
            <span style={{ marginLeft: 0 }}>
              <ConnectWallet headerButton />
            </span>
          </Box>
        </div>
      </Hidden>
    </div>
  );
};
const Href = styled.a`
  a:hover {
    text-decoration: none;
  }
`;
const ContainerDiv = styled.div`
  padding-left: 20px;
  a:hover {
    text-decoration: none;
  }
`;
const StyledDiv = styled.div`
  padding: 4px;
  margin: 2px 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(props) => props.color};
  :hover {
  }
`;

function LinkButton({ style, title, href, color, selected }) {
  return (
    <ContainerDiv style={style}>
      {href ? (
        <Link
          to={href}
          style={{
            textDecoration: "none",
          }}
        >
          <StyledDiv
            color={color}
            style={{
              color: selected ? "#00E9BA" : "white",
            }}
          >
            {title}
          </StyledDiv>
        </Link>
      ) : (
        <StyledDiv
          color={color}
          style={{
            color: selected ? "#00E9BA" : "white",
          }}
        >
          {title}
        </StyledDiv>
      )}
    </ContainerDiv>
  );
}

export default MenuItems;
