export const FETCH_WRAP_BEGIN = "FETCH_WRAP_BEGIN";
export const FETCH_WRAP_SUCCESS = "FETCH_WRAP_SUCCESS";
export const FETCH_WRAP_FAILURE = "FETCH_WRAP_FAILURE";

export const FETCH_WRAP_APPROVAL_BEGIN = "FETCH_WRAP_APPROVAL_BEGIN";
export const FETCH_WRAP_APPROVAL_SUCCESS = "FETCH_WRAP_APPROVAL_SUCCESS";
export const FETCH_WRAP_APPROVAL_FAILURE = "FETCH_WRAP_APPROVAL_FAILURE";

export const FETCH_TOKEN_APPROVAL_BEGIN = "FETCH_TOKEN_APPROVAL_BEGIN";
export const FETCH_TOKEN_APPROVAL_SUCCESS = "FETCH_TOKEN_APPROVAL_SUCCESS";
export const FETCH_TOKEN_APPROVAL_FAILURE = "FETCH_TOKEN_APPROVAL_FAILURE";

export const FETCH_SWAP_BEGIN = "FETCH_SWAP_BEGIN";
export const FETCH_SWAP_SUCCESS = "FETCH_SWAP_SUCCESS";
export const FETCH_SWAP_FAILURE = "FETCH_SWAP_FAILURE";
