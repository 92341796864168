import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_STAKE_BEGIN,
  FETCH_STAKE_SUCCESS,
  FETCH_STAKE_FAILURE,
} from "./constants";
// import { deposit } from "../../web3";
import FormData from "form-data";
import { apiUrl } from "features/configure";
import { fetchDashboard } from "features/home/redux/actions";
import { enqueueSnackbar } from "features/common/redux/actions";
import axios from "axios";
import { SiweMessage } from "siwe";
import { signMessage } from "@wagmi/core";
const domain = window.location.host;
const origin = window.location.origin;

const createSiweMessage = async (address, statement) => {
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId: "1",
  });
  return message.prepareMessage();
};

export function fetchStake({ address, tokens }) {
  return (dispatch) => {
    dispatch({
      type: FETCH_STAKE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        const message = await createSiweMessage(
          address,
          "Sign in to verify that you own this address to complete the staking process."
        );
        const signature = await signMessage(message);
        let results = await axios.post(apiUrl + "staking/enter", {
          tokens,
          signature,
          message,
        });
        dispatch({
          type: FETCH_STAKE_SUCCESS,
        });
        dispatch(fetchDashboard({ address }));
        dispatch(
          enqueueSnackbar({
            message: "Stake Success!",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );
        resolve();
      } catch (error) {
        let message = error.message || error;
        if (
          error.response &&
          (error.response.status == 409 || error.response.status == 400)
        ) {
          message = error.response.data.error;
        }
        dispatch({
          type: FETCH_STAKE_FAILURE,
        });
        dispatch(
          enqueueSnackbar({
            message: message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          })
        );
      }
    });
    return promise;
  };
}

export function useFetchStake() {
  const dispatch = useDispatch();

  const { fetchStakePending } = useSelector((state) => ({
    fetchStakePending: state.stake.fetchStakePending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchStake(data));
    },
    [dispatch]
  );

  return {
    fetchStake: boundAction,
    fetchStakePending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_STAKE_BEGIN:
      return {
        ...state,
        fetchStakePending: true,
      };

    case FETCH_STAKE_SUCCESS:
      return {
        ...state,
        fetchStakePending: false,
      };

    case FETCH_STAKE_FAILURE:
      return {
        ...state,
        fetchStakePending: false,
      };

    default:
      return state;
  }
}
