import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_WRAP_BEGIN,
  FETCH_WRAP_SUCCESS,
  FETCH_WRAP_FAILURE,
} from "./constants";
import { erc20ABI, contracts, tokens, apiBaseUrl, MAX } from "../../configure";
import {
  convertAmountFromRawNumber,
  convertAmountToRawNumber,
} from "../../helpers/bignumber";
import axios from "axios";
import _ from "lodash";
import { readContracts, fetchBalance } from "@wagmi/core";
function apiRequestUrl(methodName, queryParams) {
  return (
    apiBaseUrl + methodName + "?" + new URLSearchParams(queryParams).toString()
  );
}
export function fetchSwapDetail({
  address,
  inputToken,
  outputToken,
  inputData,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_WRAP_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const tokenAddress = tokens.basicRewardAddress;
        const inputTokenAddress = inputToken.address;
        const outputTokenAddress = outputToken.address;
        const ethAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
        const inputContract = { abi: erc20ABI, address: inputTokenAddress };
        const outputContract = { abi: erc20ABI, address: outputTokenAddress };
        const tokenContract = { abi: erc20ABI, address: tokenAddress };
        let outputValue = "";
        const promises = [];
        promises.push(axios.get(apiRequestUrl("/tokens", {})));
        if (inputData && inputData > 0) {
          promises.push(
            axios.get(
              apiRequestUrl("/quote", {
                fromTokenAddress: inputTokenAddress,
                toTokenAddress: outputTokenAddress,
                amount: convertAmountToRawNumber(
                  inputData,
                  inputToken.decimals
                ),
              })
            )
          );
        } else {
          promises.push("");
        }
        const requestData = await Promise.all(promises);
        if (inputData && inputData > 0) {
          outputValue = convertAmountFromRawNumber(
            requestData[1].data.toTokenAmount,
            outputToken.decimals
          );
        } else {
          outputValue = 0;
        }

        let outputBalance = 0;
        let inputBalance = 0;
        let inputAllowance = 0;
        const calls = [
          { ...outputContract, functionName: "balanceOf", args: [address] },
          { ...inputContract, functionName: "balanceOf", args: [address] },
          {
            ...inputContract,
            functionName: "allowance",
            args: [address, contracts.oneInchRouter.address],
          },
          { ...tokenContract, functionName: "balanceOf", args: [address] },
        ];
        const results = await readContracts({ contracts: calls });
        outputBalance = results[0].result
          ? convertAmountFromRawNumber(results[0].result, outputToken.decimals)
          : 0;
        inputBalance = results[1].result
          ? convertAmountFromRawNumber(results[1].result, inputToken.decimals)
          : 0;
        inputAllowance = results[2].result
          ? convertAmountFromRawNumber(results[2].result, inputToken.decimals)
          : 0;
        const tokenBalance = results[3].result
          ? convertAmountFromRawNumber(results[3].result)
          : 0;
        const ethResult = await fetchBalance({ address });
        const ethBalance = ethResult
          ? convertAmountFromRawNumber(ethResult.value)
          : 0;

        if (inputTokenAddress == ethAddress) {
          inputBalance = ethBalance;
        }
        if (outputTokenAddress == ethAddress) {
          outputBalance = ethBalance;
        }
        inputBalance = (Math.floor(inputBalance * 1e6) / 1e6).toString();
        outputBalance = (Math.floor(outputBalance * 1e6) / 1e6).toString();
        const output = {
          outputBalance,
          inputBalance,
          outputValue,
          tokenBalance,
          tokenList: _.reduce(
            requestData[0].data.tokens,
            function (result, value, key) {
              result.push(value);
              return result;
            },
            []
          ),
          allowance: inputTokenAddress == ethAddress ? MAX : inputAllowance,
        };
        dispatch({
          type: FETCH_WRAP_SUCCESS,
          data: output,
        });
      } catch (error) {
        dispatch({
          type: FETCH_WRAP_FAILURE,
        });
        console.log(error);
      }
    });

    return promise;
  };
}

export function useFetchSwapDetail() {
  const dispatch = useDispatch();

  const { detail, fetchSwapDetailPending } = useSelector(
    (state) => ({
      fetchSwapDetailPending: state.swap.fetchSwapDetailPending,
      detail: state.swap.detail,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchSwapDetail(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchSwapDetail: boundAction,
    fetchSwapDetailPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_WRAP_BEGIN:
      return {
        ...state,
        fetchSwapDetailPending: true,
      };

    case FETCH_WRAP_SUCCESS:
      return {
        ...state,
        detail: action.data,
        fetchSwapDetailPending: false,
      };

    case FETCH_WRAP_FAILURE:
      return {
        ...state,
        fetchSwapDetailPending: false,
      };

    default:
      return state;
  }
}
