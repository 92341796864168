import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import styles from "./styles";

const useStyles = makeStyles(styles);

const Footer = () => {
  return (
    <div style={{ textAlign: "center", fontSize: 18, marginTop: 120 }}>
      Powered by{" "}
      <a href="https://vault.inc" target="_blank">
        Vault.Inc
      </a>
    </div>
  );
};

export default memo(Footer);
