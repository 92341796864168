import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  POOL_FETCH_BEGIN,
  POOL_FETCH_SUCCESS,
  POOL_FETCH_FAILURE,
} from "./constants";
import { multicall } from "@wagmi/core";
import { erc20ABI, stakingPoolABI, contracts, pools } from "../../configure";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";
import _ from "lodash";

export function fetchPoolDetails() {
  return (dispatch, getState) => {
    dispatch({
      type: POOL_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      let calls = [];
      for (let i = 0; i < pools.length; i++) {
        let poolAddress = pools[i].poolAddress;
        let tokenContract = {
          abi: erc20ABI,
          address: pools[i].tokenAddress,
        };

        let poolContract = {
          abi: stakingPoolABI,
          address: pools[i].poolAddress,
        };

        let call = {
          ...tokenContract,
          functionName: "balanceOf",
          args: [poolAddress],
        };
        calls.push(call);

        let call2 = {
          ...poolContract,
          functionName: "totalSupply",
        };

        calls.push(call2);
      }

      const data = await multicall({ contracts: [calls] });
      let output = [];
      for (let i = 0; i < pools.length; i++) {
        const poolTotalStaked = convertAmountFromRawNumber(
          _.get(data, `[${i * 2}].result`, 0)
        );
        const poolTotalSupply = convertAmountFromRawNumber(
          _.get(data, `[${i * 2 + 1}].result`, 0)
        );

        output[i] = {
          totalStaked: poolTotalStaked,
          totalSupply: poolTotalSupply,
        };
      }
      dispatch({
        type: POOL_FETCH_SUCCESS,
        data: output,
      });
      resolve();
    });

    return promise;
  };
}

export function useFetchPoolDetails() {
  const dispatch = useDispatch();

  const { poolDetails, fetchPoolDetailsPending, fetchPoolDetailsDone } =
    useSelector(
      (state) => ({
        poolDetails: state.home.poolDetails,
        fetchPoolDetailsPending: state.home.fetchPoolDetailsPending,
        fetchPoolDetailsDone: state.home.fetchPoolDetailsDone,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchPoolDetails(data));
    },
    [dispatch]
  );

  return {
    poolDetails,
    fetchPoolDetails: boundAction,
    fetchPoolDetailsDone,
    fetchPoolDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case POOL_FETCH_BEGIN:
      return {
        ...state,
        fetchPoolDetailsPending: true,
      };

    case POOL_FETCH_SUCCESS:
      return {
        ...state,
        poolDetails: action.data,
        fetchPoolDetailsDone: true,
        fetchPoolDetailsPending: false,
      };

    case POOL_FETCH_FAILURE:
      return {
        ...state,
        fetchPoolDetailsPending: false,
      };

    default:
      return state;
  }
}
