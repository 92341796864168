import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
  selectedToggle: {
    padding: "3px 3px",
    minWidth: 80,
    borderRadius: 10,
    fontWeight: 600,
    backgroundColor: theme.palette.background.secondary,
    "& .MuiToggleButton-label": {
      color: theme.palette.text.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.primary,
      "& .MuiToggleButton-label": {
        color: theme.palette.text.primary,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.primary,
        "& .MuiButton-label": {
          color: theme.palette.text.primary,
        },
      },
    },
  },
}));

export default function CustomToggle({ value, onChange, data, fullWidth }) {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={onChange}
      style={{ textAlign: "right" }}
    >
      {data.map((button, index) => (
        <ToggleButton
          key={index}
          value={button.value}
          classes={{ root: classes.selectedToggle }}
        >
          {button.text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

CustomToggle.defaultProps = {};
