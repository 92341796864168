const styles = (theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 10,

    paddingTop: 15,
    color: theme.palette.text.white,
    borderBottom: "0px",
    // "&:first-child": {
    //   borderTopLeftRadius: 30,
    //   borderBottomLeftRadius: 30,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 30,
    //   borderBottomRightRadius: 30,
    // },
  },
  tableTitle: {
    
    backdropFilter: "blur(59.7469px)",
    // borderBottom: `2px solid ${theme.palette.text.white}`,
  },
  outlinedTitle: {
    border: `1px solid ${theme.palette.text.white}`,
  },

  columnTitle: {
    borderBottom: `1px solid ${theme.palette.text.white}`,
    borderRight: `1px solid ${theme.palette.text.white}`,
  },
  value: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.white,

    // "&:first-child": {
    //   borderTopLeftRadius: 10,
    //   borderBottomLeftRadius: 10,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 10,
    //   borderBottomRightRadius: 10,
    // },
  },

  card: {
    flexGrow: 1,
    overflow: "auto",
    borderRadius: 30,
    marginTop: 10,
  },
  tableValue: {
    borderBottom: `0px solid ${theme.palette.text.white}`,
    // padding: "3px",
  },
  outlinedValue: {
    border: `0px solid ${theme.palette.text.white}`,
    padding: 10,
  },
  columnValue: {
    borderRight: `0px solid ${theme.palette.text.white}`,
    borderBottom: "none",
  },
  selected: {
    backgroundColor: "rgb(41,76,52)",
  },
});

export default styles;
