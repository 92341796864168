import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_SWAP_BEGIN,
  FETCH_SWAP_SUCCESS,
  FETCH_SWAP_FAILURE,
} from "./constants";
import { deposit } from "../../web3";
import { fetchSwapDetail } from "./action";
import { enqueueSnackbar } from "features/common/redux/actions";
import {
  convertAmountFromRawNumber,
  convertAmountToRawNumber,
} from "../../helpers/bignumber";
import { erc20ABI, apiBaseUrl, broadcastApiUrl } from "../../configure";
import axios from "axios";
import { sendTransaction } from "@wagmi/core";

function apiRequestUrl(methodName, queryParams) {
  return (
    apiBaseUrl + methodName + "?" + new URLSearchParams(queryParams).toString()
  );
}

export function fetchSwap({
  address,
  inputToken,
  outputToken,
  inputData,
  slippage,
}) {
  return (dispatch) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const inputTokenAddress = inputToken.address;
        const outputTokenAddress = outputToken.address;
        const result = await axios.get(
          apiRequestUrl("/swap", {
            fromTokenAddress: inputTokenAddress,
            toTokenAddress: outputTokenAddress,
            fromAddress: address,
            slippage,
            amount: convertAmountToRawNumber(inputData, inputToken.decimals),
          })
        );
        const transaction = result.data.tx;
        dispatch({
          type: FETCH_SWAP_BEGIN,
        });
        await sendTransaction(transaction);

        dispatch({
          type: FETCH_SWAP_SUCCESS,
        });
        dispatch(
          fetchSwapDetail({
            address,
            inputToken,
            outputToken,
            inputData,
          })
        );
        dispatch(
          enqueueSnackbar({
            key: new Date().getTime() + Math.random(),
            message: "Swap Success",
            options: {
              variant: "success",
            },
          })
        );
      } catch (error) {
        dispatch({
          type: FETCH_SWAP_FAILURE,
        });
        dispatch(
          enqueueSnackbar({
            message: error.message || error,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          })
        );
      }
    });
    return promise;
  };
}

export function useFetchSwap() {
  const dispatch = useDispatch();

  const { fetchSwapPending } = useSelector((state) => ({
    fetchSwapPending: state.swap.fetchSwapPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchSwap(data));
    },
    [dispatch]
  );

  return {
    fetchSwap: boundAction,
    fetchSwapPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_SWAP_BEGIN:
      return {
        ...state,
        fetchSwapPending: true,
      };

    case FETCH_SWAP_SUCCESS:
      return {
        ...state,
        fetchSwapPending: false,
      };

    case FETCH_SWAP_FAILURE:
      return {
        ...state,
        fetchSwapPending: false,
      };

    default:
      return state;
  }
}
