import React, { useState, useEffect } from "react";
import useThrottledEffect from "use-throttled-effect";
import Button from "components/CustomButtons/Button.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  inputLimitPass,
  inputFinalVal,
  callContract,
} from "features/helpers/utils";
import _, { set } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFetchSwapDetail, useFetchSwap } from "features/swap/redux/hooks";
import { contracts, erc20ABI, MAX } from "features/configure";
import { Grid, Modal, IconButton } from "@material-ui/core";

import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import CustomToggle from "components/CustomToggle/CustomToggle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import { useAccount } from "wagmi";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "30%",
    minWidth: 360,

    color: "white",
    backgroundColor: theme.palette.background.paper,
    padding: "15px 25px",
    borderRadius: 20,
  },
  tokenList: {
    fontWeight: 500,
    fontSize: 20,
    minWidth: 100,
    textAlign: "left",
    padding: 10,
    color: "white",
    cursor: "pointer",
  },
  currency: {
    fontWeight: 500,
    fontSize: 20,
    minWidth: 120,
    textAlign: "left",
    verticalAlign: "middle",
    color: "white",
    cursor: "pointer",
  },
}));
const sea = {
  symbol: "BREED",
  name: "Breeder DAO",
  address: "0x94e9eb8b5ab9fd6b9ea3169d55ffade62a01702e",
  decimals: 18,
  logoURI: require("assets/img/breed_token.png"),
};
export default function Swap() {
  const [inputBalance, setInputBalance] = useState("");
  const [outputBalance, setOutputBalance] = useState("");
  const [search, setSearch] = useState("");
  const [buy, setBuy] = useState(true);
  const [inputCurrency, setInputCurrency] = useState({
    symbol: "ETH",
    name: "Ethereum",
    decimals: 18,
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    logoURI:
      "https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png",
  });

  const [outputCurrency, setOutputCurrency] = useState({ ...sea });
  const { fetchSwapDetail, fetchSwapDetailPending, detail } =
    useFetchSwapDetail();
  const { fetchSwap, fetchSwapPending } = useFetchSwap();
  const { address } = useAccount();
  const classes = useStyles();
  const [fetchApprovalPending, setFetchApprovalPending] = useState(false);
  const [open, setOpen] = useState(false);
  const [slippage, setSlippage] = useState(1);

  const [openSettings, setOpenSettings] = useState(false);
  const tokenList = search
    ? _.filter(detail.tokenList, function (o) {
        return _.toUpper(o.symbol).includes(_.toUpper(search));
      })
    : detail.tokenList;
  const handleOpen = (isBuy) => {
    if (isBuy == buy) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSlippage = (event, newsetSlippage) => {
    setSlippage(newsetSlippage);
  };

  useThrottledEffect(
    () => {
      if (address) {
        fetchSwapDetail({
          address,
          inputToken: inputCurrency,
          outputToken: outputCurrency,
          inputData: inputBalance,
        });
      }
    },
    1000,
    [address, inputCurrency, inputBalance, outputCurrency]
  );

  useEffect(() => {
    if (address) {
      const id = setInterval(() => {
        fetchSwapDetail({
          address,
          inputToken: inputCurrency,
          outputToken: outputCurrency,
          inputData: inputBalance,
        });
      }, 3000);
      return () => clearInterval(id);
    }
  }, [address, inputCurrency, inputBalance, outputCurrency]);

  const revertCurrency = () => {
    const cloneInput = { ...inputCurrency };
    const cloneInputBalance = inputBalance;
    setInputBalance(outputBalance);
    setInputCurrency(outputCurrency);
    setOutputBalance(cloneInputBalance);
    setOutputCurrency(cloneInput);
    if (buy) {
      setBuy(false);
    } else {
      setBuy(true);
    }
  };
  const setInputMaximum = () => {
    const total = detail.inputBalance;

    if (total > 0) {
      const amount = total;
      setInputBalance(amount.replaceAll(",", ""));
    }
  };

  const changeSearchValue = (event) => {
    let value = event.target.value;
    setSearch(value);
  };
  const changeInputValue = (event) => {
    let value = event.target.value;
    const total = detail.inputBalance;
    if (!inputLimitPass(value)) {
      return;
    }
    setInputBalance(inputFinalVal(value, total));
  };

  const onApproval = async () => {
    try {
      setFetchApprovalPending(true);
      await callContract({
        address: inputCurrency.address,
        abi: erc20ABI,
        functionName: "approve",
        args: [contracts.oneInchRouter.address, MAX],
      });
    } catch (err) {
    } finally {
      setFetchApprovalPending(false);
    }
  };

  const onSwap = () => {
    let amountValue = inputBalance
      ? inputBalance.replaceAll(",", "")
      : inputBalance;

    fetchSwap({
      address,
      slippage,
      inputToken: inputCurrency,
      outputToken: outputCurrency,
      inputData: inputBalance,
    }).then(() => {});
  };

  return (
    <div style={{ padding: "100px 0", minHeight: "100vh" }}>
      <Modal
        className={classes.modal}
        open={fetchSwapPending || fetchApprovalPending}
      >
        <div
          className={classes.paper}
          style={{ height: 120, textAlign: "center" }}
        >
          <CircularProgress />
          <h2>Loading...</h2>
        </div>
      </Modal>
      <Modal className={classes.modal} open={open} onClose={handleClose}>
        <div className={classes.paper} style={{ height: 480 }}>
          <CustomOutlinedInput
            value={search}
            placeholder="search"
            onChange={changeSearchValue}
          />
          <div style={{ overflow: "auto", margin: 20, height: 330 }}>
            {tokenList &&
              tokenList.map((token, index) => {
                return (
                  <div
                    key={index}
                    className={classes.tokenList}
                    onClick={() => {
                      if (buy) {
                        setInputCurrency(token);
                        setInputBalance("");
                      } else {
                        setOutputCurrency(token);
                        setOutputBalance("");
                      }
                      handleClose();
                    }}
                  >
                    <span>
                      <img src={token.logoURI} className="smIcon" />
                    </span>
                    <span>{token.symbol}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
      <Modal
        className={classes.modal}
        open={openSettings}
        onClose={() => setOpenSettings(false)}
      >
        <div className={classes.paper}>
          <h3>slippage tolerance</h3>
          <CustomToggle
            value={slippage}
            onChange={handleSlippage}
            data={[
              { value: 1, text: "1%" },
              { value: 3, text: "3%" },
              { value: 5, text: "5%" },
              { value: 10, text: "10%" },
            ]}
          />
        </div>
      </Modal>
      <Grid container>
        <Grid item xs={12} sm={2} />
        <Grid item xs={12} sm={4}>
          <div className="card" style={{ maxWidth: 400, margin: "0 auto" }}>
            <div style={{ textAlign: "right" }}>
              <IconButton onClick={() => setOpenSettings(true)}>
                <SettingsIcon />
              </IconButton>
            </div>

            <div
              className="card-black"
              style={{ maxWidth: 650, margin: "0 auto" }}
            >
              <div>
                <CustomOutlinedInput
                  value={inputBalance}
                  startAdornment={
                    <div
                      className={classes.currency}
                      onClick={() => {
                        handleOpen(true);
                      }}
                    >
                      <span>
                        <img src={inputCurrency.logoURI} className="smIcon" />
                      </span>
                      <span>{inputCurrency.symbol}</span>
                      {buy && (
                        <ExpandMoreIcon style={{ verticalAlign: "middle" }} />
                      )}
                    </div>
                  }
                  availabletext={`Wallet: ${_.get(
                    detail,
                    "inputBalance",
                    "-"
                  )}`}
                  onChange={changeInputValue}
                  setMax={() => setInputMaximum()}
                />
                {/* <div>
                <IconButton onClick={()=>{
                  revertCurrency()
                }} ><ImportExportIcon style={{ fontSize: 40 }}/></IconButton>
              </div> */}
                <CustomOutlinedInput
                  value={
                    parseFloat(detail.outputValue)
                      ? parseFloat(detail.outputValue).toFixed(2)
                      : 0
                  }
                  disabled
                  startAdornment={
                    <div
                      className={classes.currency}
                      onClick={() => {
                        handleOpen(false);
                      }}
                    >
                      <span>
                        <img src={outputCurrency.logoURI} className="smIcon" />
                      </span>
                      <span>{outputCurrency.symbol}</span>
                      {!buy && (
                        <ExpandMoreIcon style={{ verticalAlign: "middle" }} />
                      )}
                    </div>
                  }
                  onClick={() => {}}
                  availabletext={`Wallet: ${_.get(
                    detail,
                    "outputBalance",
                    "-"
                  )}`}
                  // onChange={changeOutputValue}
                  // setMax={() => setOutputMaximum()}
                />

                <div style={{ marginTop: 40 }}>
                  {detail && parseFloat(detail.allowance) > 0 ? (
                    <Button
                      disabled={
                        parseFloat(detail.outputValue) == 0 || fetchSwapPending
                      }
                      fullWidth
                      onClick={() => {
                        onSwap();
                      }}
                      color="secondary"
                    >
                      Swap
                    </Button>
                  ) : (
                    <Button
                      disabled={fetchApprovalPending}
                      fullWidth
                      onClick={() => {
                        onApproval();
                      }}
                      color="secondary"
                    >
                      Approve
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            {detail.tokenBalance && parseFloat(detail.tokenBalance) > 0 && (
              <Link to="/">
                <Button onClick={() => {}} color="secondary">
                  Go To Stake
                </Button>
              </Link>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="card" style={{ margin: "0 auto" }}>
            <h1>Important to know when swapping</h1>
            <ul>
              <li>
                <h3>
                  We created a swap page using the 1inch API in case you want to
                  swap for BREED, If you encounter any difficulties, please try
                  using{" "}
                  <a
                    href="https://app.uniswap.org/#/swap?inputCurrency=0x94e9eb8b5ab9fd6b9ea3169d55ffade62a01702e&outputCurrency=ETH&chain=mainnet"
                    target="_blank"
                  >
                    Uniswap UI
                  </a>{" "}
                  instead.
                </h3>
              </li>
              <li>
                <h3>
                  Always make sure that you have sufficient ETH for gas fees in
                  your wallet
                </h3>
              </li>
              <li>
                <h3>
                  We have paired $BREED with ETH. You can swap against any token
                  you like but swapping with other assets could result in
                  slippage depending on the routing of your order.
                </h3>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
