import { App } from "../features/home";
import homeRoute from "../features/home/route";
import stakeRoute from "../features/stake/route";
import rewardRoute from "../features/reward/route";
import swapRoute from "../features/swap/route";
import leaderboardRoute from "../features/leaderboard/route";
// NOTE: DO NOT CHANGE the 'childRoutes' name and the declaration pattern.
// This is used for Rekit cmds to register routes config for new features, and remove config when remove features, etc.

const routes = [
  {
    path: "/",
    component: App,
    childRoutes: [homeRoute, stakeRoute, rewardRoute,swapRoute, leaderboardRoute],
    // childRoutes: [homeRoute, stakeRoute, rewardRoute, leaderboardRoute],
  },
];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = route.childRoutes.find((child) => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = "";
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
