import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_REWARD_DETAIL_BEGIN,
  FETCH_REWARD_DETAIL_SUCCESS,
  FETCH_REWARD_DETAIL_FAILURE,
} from "./constants";
import {
  escrowedRewardABI,
  erc721stakingABI,
  stakedERC721ABI,
  contracts,
} from "../../configure";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";
import { readContracts, readContract } from "@wagmi/core";

export function fetchRewardDetail({ address, data }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_REWARD_DETAIL_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const nftStakingContract = {
          abi: erc721stakingABI,
          address: contracts.nftStaking.address,
        };
        const stakedNftContract = {
          abi: stakedERC721ABI,
          address: contracts.stakedNFT.address,
        };
        const escrowedContract = {
          abi: escrowedRewardABI,
          address: contracts.escrowedReward.address,
        };

        const calls = [
          {
            ...nftStakingContract,
            functionName: "userStakedPower",
            args: [address],
          },
          {
            ...nftStakingContract,
            functionName: "withdrawableRewardsOf",
            args: [address],
          },
          {
            ...stakedNftContract,
            functionName: "balanceOf",
            args: [address],
          },
        ];
        const results = await readContracts({ contracts: calls });

        const output = {};

        output[0] = {
          stakedToken: results[0].result
            ? convertAmountFromRawNumber(results[0].result)
            : 0,
          claimableTokenReward: results[1].result
            ? convertAmountFromRawNumber(results[1].result)
            : 0,
          stakedNFT: results[2].result ? results[2].result : 0,
        };

        const escrowedData = await readContract({
          ...escrowedContract,
          functionName: "getDepositsOf",
          args: [address],
        });

        output["escrowedData"] = escrowedData;

        dispatch({
          type: FETCH_REWARD_DETAIL_SUCCESS,
          data: output,
        });
        resolve();
      } catch (error) {
        dispatch({
          type: FETCH_REWARD_DETAIL_FAILURE,
        });
        return reject(error.message || error);
      }
    });

    return promise;
  };
}

export function useFetchRewardDetail() {
  const dispatch = useDispatch();

  const { detail, fetchRewardPending } = useSelector(
    (state) => ({
      fetchRewardPending: state.reward.fetchRewardPending,
      detail: state.reward.detail,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchRewardDetail(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchRewardDetail: boundAction,
    fetchRewardPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_REWARD_DETAIL_BEGIN:
      return {
        ...state,
        fetchRewardPending: true,
      };

    case FETCH_REWARD_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.data,
        fetchRewardPending: false,
      };

    case FETCH_REWARD_DETAIL_FAILURE:
      return {
        ...state,
        fetchRewardPending: false,
      };

    default:
      return state;
  }
}
