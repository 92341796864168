import { stakingPoolABI, erc721stakingABI } from "../configure";
import { enqueueSnackbar } from "../common/redux/actions";
import {
  FETCH_CLAIM_SINGLE_TOKEN_BEGIN,
  FETCH_CLAIM_SINGLE_TOKEN_SUCCESS,
} from "features/reward/redux/constants";
import { writeContract } from "@wagmi/core";
export const claimReward = async ({
  address,
  contractAddress,

  dispatch,
}) => {
  try {
    dispatch({
      type: FETCH_CLAIM_SINGLE_TOKEN_BEGIN,
    });
    const { hash } = await writeContract({
      address: contractAddress,
      abi: erc721stakingABI,
      functionName: "claimRewards",
      args: [address],
      account: address,
    });
    dispatch({
      type: FETCH_CLAIM_SINGLE_TOKEN_SUCCESS,
    });
    dispatch(
      enqueueSnackbar({
        message: "Claim Success: " + hash,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
        hash,
      })
    );
  } catch (err) {
    enqueueSnackbar({
      message: "Claim Error",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    });
  }
};
