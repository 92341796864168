import { reducer as fetchDashboardReducer } from "./fetchDashboard";
import { reducer as fetchPoolDetailsReducer } from "./fetchPoolDetails";
import { reducer as fetchRewardPoolsDetailsReducer } from "./fetchRewardPoolsDetails";
import { reducer as fetchWithdrawReducer } from "./fetchWithdraw";
const reducers = [
  fetchDashboardReducer,
  fetchPoolDetailsReducer,
  fetchRewardPoolsDetailsReducer,
  fetchWithdrawReducer,
];

const initialState = {
  address: "",
  connected: false,
  networkId: Number(process.env.REACT_APP_NETWORK_ID),
  detail: {},
  userDetails: [],
  fetchWithdrawPending: [false],
};

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
