import React, { useEffect, useState } from "react";
import _ from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    minWidth: 100,
    zIndex: 0,
    color: "white",
    background: "transparent",
  },
  icon: {
    color: "white",
    right: 0,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      background: "transparent",
    },
    "&:hover": {
      opacity: 1,
    },
  },
}))(MenuItem);

export const LanguageSelector = () => {
  const languages = {
    en: "EN",
    zh: "繁體中文",
    cn: "简体中文",
  };
  const [lng, setLanguage] = useState("en");
  const classes = useStyles();

  const IconComponent = (props) => {
    return <ExpandMoreIcon className={classes.icon} />;
  };
  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <>
      <Box display="flex" justifyContent={"center"} alignItems={"center"}>
        <LanguageIcon style={{ marginLeft: "16px" }} />
        <Select
          disableUnderline
          value={lng}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            MenuListProps: { disablePadding: true },
            classes: { paper: classes.menuPaper },
          }}
          style={{ padding: 0, color: "white" }}
          onChange={handleChange}
          IconComponent={IconComponent}
        >
          {Object.keys(languages).map((language) => (
            <StyledMenuItem key={language} value={language}>
              {languages[language]}
            </StyledMenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};

export default LanguageSelector;
