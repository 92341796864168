import { erc721stakingABI, contracts } from "../configure";
import { enqueueSnackbar } from "../common/redux/actions";
import {
  FETCH_BATCH_STAKE_BEGIN,
  FETCH_BATCH_STAKE_SUCCESS,
  FETCH_BATCH_STAKE_FAILURE,
} from "features/stake/redux/constants";
import _ from "lodash";
import { writeContract } from "@wagmi/core";
export const batchDeposit = async ({
  address,
  tokenIds,
  duration,
  dispatch,
}) => {
  // here duration is week, need to multiple 7*24*60*60

  let lockPeriod =
    duration * 30 * 24 * 60 * 60 > 86400 * 30
      ? duration * 30 * 24 * 60 * 60
      : 86400 * 30;

  try {
    dispatch({
      type: FETCH_BATCH_STAKE_BEGIN,
    });
    const { hash } = await writeContract({
      address: contracts.nftStaking.address,
      abi: erc721stakingABI,
      functionName: "batchStake",
      args: [tokenIds, _.map(tokenIds, () => lockPeriod)],
      account: address,
    });

    dispatch({
      type: FETCH_BATCH_STAKE_SUCCESS,
    });
    dispatch(
      enqueueSnackbar({
        key: new Date().getTime() + Math.random(),
        message: "Stake Success",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    enqueueSnackbar({
      message: "Batch Stake Error",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    });
  }
};
