import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  REWARD_POOLS_FETCH_BEGIN,
  REWARD_POOLS_FETCH_SUCCESS,
  REWARD_POOLS_FETCH_FAILURE,
} from "./constants";
import { readContracts } from "@wagmi/core";
import { erc20ABI, contracts } from "../../configure";
import _ from "lodash";

export function fetchRewardPoolsDetails({ reward }) {
  return (dispatch, getState) => {
    dispatch({
      type: REWARD_POOLS_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      let calls = [];
      const rewardTokenContract = {
        abi: erc20ABI,
        address: reward.tokenAddress,
      };
      for (let reward of contracts.escrowedRewards) {
        calls.push({
          ...rewardTokenContract,
          functionName: "balanceOf",
          args: [reward.escrowPool],
        });
      }
      try {
        const data = await readContracts({
          contracts: calls,
        });

        let output = [];
        contracts.escrowedRewards.forEach(function (reward, j) {
          const rewardPoolData = {
            tokenName: reward.tokenName,
            tokenAddress: reward.tokenAddress,
            balance: _.get(data, `[${j}].result`, 0).toString(),
          };
          output[j] = rewardPoolData;
        });

        dispatch({
          type: REWARD_POOLS_FETCH_SUCCESS,
          data: output,
        });
        resolve();
      } catch (error) {
        dispatch({
          type: REWARD_POOLS_FETCH_FAILURE,
        });
        return reject(error.message || error);
      }
    });

    return promise;
  };
}

export function useFetchRewardPoolsDetails() {
  const dispatch = useDispatch();

  const {
    rewardPoolsDetails,
    fetchRewardPoolsDetailsPending,
    fetchRewardPoolsDetailsDone,
  } = useSelector(
    (state) => ({
      rewardPoolsDetails: state.home.rewardPoolsDetails,
      fetchRewardPoolsDetailsPending: state.home.fetchRewardPoolsDetailsPending,
      fetchRewardPoolsDetailsDone: state.home.fetchRewardPoolsDetailsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchRewardPoolsDetails(data));
    },
    [dispatch]
  );

  return {
    rewardPoolsDetails,
    fetchRewardPoolsDetails: boundAction,
    fetchRewardPoolsDetailsDone,
    fetchRewardPoolsDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REWARD_POOLS_FETCH_BEGIN:
      return {
        ...state,
        fetchRewardPoolsDetailsPending: true,
      };

    case REWARD_POOLS_FETCH_SUCCESS:
      return {
        ...state,
        rewardPoolsDetails: action.data ? action.data : 0,
        fetchRewardPoolsDetailsDone: true,
        fetchRewardPoolsDetailsPending: false,
      };

    case REWARD_POOLS_FETCH_FAILURE:
      return {
        ...state,
        fetchRewardPoolsDetailsPending: false,
      };

    default:
      return state;
  }
}
