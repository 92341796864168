export {
  stakingPoolABI,
  erc20ABI,
  erc721ABI,
  escrowedRewardABI,
  liquidityMiningManagerABI,
  viewABI,
  uniswapV2PairABI,
  balancerVaultABI,
  liquidityBootstrappingPoolABI,
  erc721stakingABI,
  stakedERC721ABI,
} from "./abi";
export { tokens } from "./tokens";
export { pools } from "./pools";
export { contracts } from "./contracts";
export const websiteUrl = {};
export const apiUrl = "https://analytics.vault.inc/";
// export const apiUrl = "http://localhost:21667/api/";
export const apiBaseUrl = "https://api.1inch.io/v4.0/1";
export const nftApiUrl =
  "https://api.zee-verse.com/metadata/0xBe3A930e8c9dEeA1daE40a192fD221cc65Aed4E6/";
export const broadcastApiUrl = "https://tx-gateway.1inch.io/v1.1/1/broadcast";
export const MAX =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";
