import axios from "axios";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from "./constants";

import _ from "lodash";

export function fetchPrice() {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRICE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let promises = [];

      // promises.push(copperLaunchPoolContract.methods.getNormalizedWeights().call());
      // promises.push(balancerVaulContract.methods.getPoolTokens(contracts.balancerVault.poolId).call());
      promises.push(
        axios.get(
          "https://coins.llama.fi/prices/current/ethereum:0x7616113782aadab041d7b10d474f8a0c04eff258?searchWidth=4h"
        )
      );

      Promise.all(promises)
        .then((data) => {
          dispatch({
            type: FETCH_PRICE_SUCCESS,
            data,
          });
          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: FETCH_PRICE_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceDatas, lpData, chart, ethData } = useSelector(
    (state) => ({
      fetchPricePending: state.price.fetchPricePending,
      priceDatas: state.price.priceDatas,
    })
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceDatas,
    lpData,
    ethData,
    chart,
  };
}

export function reducer(
  state = { fetchPricePending: false, priceDatas: {} },
  action
) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending,
        },
      };

    case FETCH_PRICE_SUCCESS:
      let priceDatas = {};

      // const tokenWeight = convertAmountFromRawNumber(action.data[0][0]);
      // const usdcWeight = convertAmountFromRawNumber(action.data[0][1]);
      // const tokenBalances = convertAmountFromRawNumber(action.data[1].balances[0]);
      // const usdcBalances = convertAmountFromRawNumber(action.data[1].balances[1], 6);

      // {
      //   "coins": {
      //     "ethereum:0x7616113782aadab041d7b10d474f8a0c04eff258": {
      //       "decimals": 18,
      //       "symbol": "VEE",
      //       "price": 0.0008614,
      //       "timestamp": 1702113033,
      //       "confidence": 0.99
      //     }
      //   }
      // }
      priceDatas.vee = {
        price:
          action.data[0].data.coins[
            "ethereum:0x7616113782aadab041d7b10d474f8a0c04eff258"
          ].price,
      };
      return {
        ...state,
        priceDatas: priceDatas,
        fetchPricePending: {
          ...state.fetchPricePending,
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending,
        },
      };

    default:
      return state;
  }
}
