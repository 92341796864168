import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import PropTypes from "prop-types";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ marginTop: 24 }}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    borderRadius: 5,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  tabs: {
    color: "white",
    marginTop: 20,
    borderRadius: 5,
    backgroundColor: "#080B1D",
  },
  tab: {
    fontSize: "1.1rem",
    borderRadius: 5,
    textTransform: "capitalize",
    border: "0px",

    "& .MuiTab-wrapper": {
      fontWeight: 600,
      color: "white",
    },
  },
}));

export default function CustomTabs({
  tabs,
  initIndex = 0,
  tabIndex,
  setTabIndex,
  handleChange,
}) {
  const [value, setValue] = useState(parseInt(initIndex));
  const theme = useTheme();

  const classes = useStyles();
  return (
    <div>
      <AppBar position="static" className={classes.tabs}>
        <Tabs
          TabIndicatorProps={{ style: { background: "transparent" } }}
          value={typeof tabIndex === "number" ? tabIndex : value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
        >
          {tabs.map((tab, index) => {
            let color =
              tabIndex == index ? "#3c784d" : "";
            return (
              <Tab
                key={tab.label}
                style={{ background: color }}
                className={`${classes.tab}`}
                label={tab.label}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => {
        return (
          <TabPanel
            key={index}
            value={typeof tabIndex === "number" ? tabIndex : value}
            index={index}
          >
            {tab.content}
          </TabPanel>
        );
      })}
    </div>
  );
}
