import { erc721stakingABI, contracts } from "../configure";
import { enqueueSnackbar } from "../common/redux/actions";
import { writeContract } from "@wagmi/core";
export const withdraw = async ({ address, tokenId, dispatch }) => {
  try {
    const { hash } = await writeContract({
      address: contracts.nftStaking.address,
      abi: erc721stakingABI,
      functionName: "unstake",
      args: [tokenId],
      account: address,
    });
    dispatch(
      enqueueSnackbar({
        message: hash,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
        hash,
      })
    );
  } catch (err) {
    enqueueSnackbar({
      message: "Withdraw Error",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    });
  }
};
