import React, { useEffect, useState } from "react";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer";
import { Notifier } from "features/common";
import createAppTheme from "./jss/appTheme";
import LandingHeader from "./components/LandingHeader";

export default function App({ children }) {
  const theme = createAppTheme(true);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div
          style={{
            background: `linear-gradient(90deg, #0e3017 3.49%, #0b2b14 39.49%, #0b3015 66.56%, #082911 96.28%)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            // backgroundAttachment: "fixed",
          }}
        >
          <LandingHeader />
          <div>
            {children}
            <Notifier />
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}
