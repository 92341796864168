export const contracts = {
  multicall: {
    address: "0x29de9dfa4adfe50c878fb2ffff8c1b28d9e24355",
  },
  nft: {
    name: "Zee Land",
    address: "0xbe3a930e8c9deea1dae40a192fd221cc65aed4e6",
  },
  stakedNFT: {
    name: "Zee Land Staked",
    address: "0xB9A0A436455360bdeEAFd4e1A97DF392C516bfdA",
  },
  nftStaking: {
    name: "Zee Land Staking",
    address: "0x95cd6D620A79998CB7519256F4d5f5cd705ac135",
  },
  liquidityMiningManager: {
    address: "0x4A9b4f7ED0C30D98baa94dFEFB97B19bB1Cc67FD",
  },
  escrowedReward: {
    address: "0x992b4Ce4aaE948404d831b97aEE23Fe367A49505",
  },
};
